@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;700&display=swap');
@import '~react-calendar/dist/Calendar.css';

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Sora';
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: 'white';
}

a:active,
a:focus {
  color: 'white';
}

button {
  all: unset;
  cursor: pointer;
  /* width: 100%; */
  box-sizing: border-box;
}

.react-calendar__tile--active {
  background-color: green !important;
  color: white !important;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  font-family: 'Sora';
  border: none;
}

.react-calendar__tile--active:hover,
.react-calendar__tile--active:focus {
  background-color: darkgreen !important;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  font-family: 'Sora';
  border: none;
}

.react-calendar {
  font-family: 'Sora';
  border: none;
  border-radius: 10px;
  border-color: green;
}

.react-calendar button {
  font-family: 'Sora';
  border: none;
}

.react-calendar__tile {
  font-family: 'Sora', sans-serif;
  color: #101828;
  font-weight: 600;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  font-family: 'Sora';
  border: none;
}

.react-calendar__navigation button {
  font-family: 'Sora', sans-serif;
  text-decoration: none;
  color: #101828;
  text-align: center;
  font-family: 'Sora';
  border: none;
}

.react-calendar__month-view__weekdays__weekday {
  font-family: 'Sora', sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  color: #667085;
  font-family: 'Sora';
  border: none;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Sora';
  border: none;
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 1.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
